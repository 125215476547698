import { useLocale } from 'hooks';
import { DemographicSelectBox } from './DemographicSelectBox';
import { DemographicSelectPills } from './DemographicSelectPills';

export const DemographicSelector = (props) => {
  const [locale] = useLocale();
  const {
    demographicId,
    demographicKey,
    values,
    options: propsOptions,
    name: {
      [locale]: name
    } = {},
    useSelectBox,
    survey_label: propsSurveyLabel,
    multi_select_options_limit,
    disabled,
    error,
    setDemographicSelection,
    toggleDemographicSelection
  } = props;

  const {
    [locale]: survey_label
  } = propsSurveyLabel || {};
  const label = survey_label || name || propsSurveyLabel?.en || demographicKey;

  const options = propsOptions.map(
    ({ name = {} }) => {
      const label = name[locale] || name?.en;

      return {
        value: name?.en,
        label: label,
      };
    }
  );

  const isMultiSelect = multi_select_options_limit && multi_select_options_limit != 1;
  const selected = isMultiSelect ? values?.[demographicKey] : values?.[demographicKey]?.[0];

  return useSelectBox ? (
    <DemographicSelectBox
      demographicId={demographicId}
      label={label}
      error={error}
      demographicKey={demographicKey}
      options={options}
      selected={selected}
      disabled={disabled}
      isMultiSelect={isMultiSelect}
      setDemographicSelection={setDemographicSelection}
    />
  ) : (
    <DemographicSelectPills
      demographicId={demographicId}
      label={label}
      error={error}
      demographicKey={demographicKey}
      options={options}
      selected={selected}
      isMultiSelect={isMultiSelect}
      setDemographicSelection={setDemographicSelection}
      toggleDemographicSelection={toggleDemographicSelection}
    />
  );
};
