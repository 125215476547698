import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { getAPI } from 'lib/APIHelpers';

const DEMOGRAPHIC_QUERY_KEY = 'getDemographicData';

export const useDemographicApi = ({ feedbackID }) => {

  const { isPending, data, isFetching } = useQuery({
    queryKey: [DEMOGRAPHIC_QUERY_KEY, feedbackID],
    queryFn: () => getAPI(`api/v2/feedback/${feedbackID}/demographics`),
    throwOnError: true
  });

  const {
    demographics,
    has_dependent_demographic_category,
    selected_demographics,
    errors
  } = data || {};

  const queryClient = useQueryClient();

  const queryKey = useMemo(() => [DEMOGRAPHIC_QUERY_KEY, feedbackID], [feedbackID]);

  const setErrors = useCallback(
    (errors) => {
      queryClient.setQueryData(queryKey, (state) => ({
        ...state,
        errors
      }));
    },
    [queryKey, queryClient]
  );

  const clearError = useCallback(
    (demographicKey) => {
      queryClient.setQueryData(queryKey, (state) => ({
        ...state,
        errors: {
          ...state.errors,
          [demographicKey]: undefined
        }
      }));
    },
    [queryKey, queryClient]
  );

  const setDemographicSelection = useCallback(
    (demographicKey, value) => {
      if (!Array.isArray(value) && value !== undefined) {
        value = [value];
      }
      queryClient.setQueryData(queryKey, ({ selected_demographics, ...otherState }) => ({
        ...otherState,
        selected_demographics: {
          ...selected_demographics,
          [demographicKey]: value
        }
      }));
      clearError(demographicKey);
    },
    [queryKey, queryClient, clearError]
  );

  const toggleDemographicSelection = useCallback(
    (demographicKey, value) => {
      queryClient.setQueryData(queryKey, ({ selected_demographics, ...otherState }) => {
        let values = [];
        if (selected_demographics && selected_demographics[demographicKey]) {
          values = [...selected_demographics[demographicKey]];
        }
        const index = values.indexOf(value);
        if (index > -1) {
          values.splice(index, 1);
        } else {
          values.push(value);
        }
        return {
          ...otherState,
          selected_demographics: {
            ...selected_demographics,
            [demographicKey]: values
          }
        };
      });
      clearError(demographicKey);
    },
    [queryKey, queryClient, clearError]
  );

  return {
    loading: isFetching || isPending,
    demographics,
    selected_demographics,
    has_dependent_demographic_category,
    setDemographicSelection,
    toggleDemographicSelection,
    errors,
    setErrors
  };
};
