import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Radio,
  RadioGroup,
  Chip,
  Checkbox,
  Box,
  Typography,
  FormControl,
  FormHelperText,
  Stack
} from '@mui/joy';

const PILLS_MAX_WIDTH = 900;

const CHIP_WRAP_PROPS = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: 2,
  justifyContent: 'center',
  maxWidth: PILLS_MAX_WIDTH,
  direction: 'ltr'
};

const DemographicChip = ({
  demographicKey,
  value,
  label,
  selected,
  isMultiSelect,
  setDemographicSelection,
  toggleDemographicSelection
}) => {

  const isSelected = useMemo(
    () => {
      if (isMultiSelect) {
        return (selected || []).includes(value);
      } else {
        return selected === value;
      }
    },
    [selected, isMultiSelect, value]
  );

  const checkboxOnChange = useCallback(
    () => toggleDemographicSelection(demographicKey, value),
    [value, demographicKey, toggleDemographicSelection]
  );

  const radioOnChange = useCallback(
    (event) => {
      if (event.target.checked) {
        setDemographicSelection(demographicKey, value);
      }
    },
    [value, demographicKey, setDemographicSelection]
  );

  return (
    <Chip
      key={value}
      color={isSelected ? 'primary' : 'neutral'}
      variant={isSelected ? 'solid' : 'plain'}
      sx={{
        '--Chip-minHeight': '32px',
        px: 2,
        py: 1,
        maxWidth: '100%',
        whiteSpace: 'wrap',
        textAlign: 'center'
      }}
    >
      {isMultiSelect ? (
        <Checkbox
          disableIcon
          overlay
          label={label}
          fontSize={16}
          value={value}
          checked={isSelected}
          variant={isSelected ? 'solid' : 'outlined'}
          onChange={checkboxOnChange}
        />
      ) : (
        <Radio
          label={label}
          value={value}
          fontSize={16}
          checked={isSelected}
          variant={isSelected ? 'solid' : 'outlined'}
          onChange={radioOnChange}
        />
      )}
    </Chip>
  );
};

export const DemographicPillsHeader = ({
  demographicId,
  label,
  isMultiSelect,
  t
}) => (
  <div
    data-cy={'demographic-label'}
  >
    <Box
      data-cy={`demographic-${label}`}
      htmlFor={`demographic-${demographicId}`}
      id={`demographic-${demographicId}-select-label`}
      sx={{
        textAlign: 'center',
        display: 'block',
        alignSelf: 'center',
        fontSize: { xs: 24, md: 32 },
        mb: 1
      }}
    >
      {label}
      {isMultiSelect ? (
        <Typography
          data-cy={'multi-select-message'}
          component={'span'}
          fontSize={'xs'}
          fontWeight={400}
          mt={1}
        >
          {t('You can select more than one option.')}
        </Typography>
      ) : (
        <Typography
          data-cy={'single-select-message'}
          component={'span'}
          fontSize={'xs'}
          fontWeight={400}
          mt={1}
        >
          {t('Select one option.')}
        </Typography>
      )}
    </Box>
  </div>
);

export const DemographicSelectPills = ({
  label,
  error,
  demographicKey,
  demographicId,
  options,
  selected,
  isMultiSelect,
  setDemographicSelection,
  toggleDemographicSelection
}) => {

  const { t } = useTranslation('demographics_page');

  const chips = useMemo(
    () => options.map(({ value, label }) => (
      <DemographicChip
        key={value}
        demographicKey={demographicKey}
        value={value}
        label={label}
        selected={selected}
        isMultiSelect={isMultiSelect}
        setDemographicSelection={setDemographicSelection}
        toggleDemographicSelection={toggleDemographicSelection}
      />
    )),
    [demographicKey, options, selected, isMultiSelect, setDemographicSelection, toggleDemographicSelection]
  );

  if (isMultiSelect) return (
    <Stack spacing={3} sx={{ border: 'none' }} alignItems={'center'}>
      <DemographicPillsHeader
        demographicId={demographicId}
        label={label}
        t={t}
        isMultiSelect={isMultiSelect}
      />
      <Box
        sx={CHIP_WRAP_PROPS}
        data-component={`demographic-${demographicId}-checkbox-group`}
        aria-labelledby={`demographic-${demographicId}-select-label`}
      >
        {chips}
      </Box>
      <Typography
        data-component={`demographic-${demographicId}-error-message`}
        alignSelf={'center'}
        aria-live={'polite'}
        color={'danger'}
        fontSize={'sm'}
      >
        {error}
      </Typography>
    </Stack>
  );

  return (
    <FormControl
      sx={{
        width: '100%',
        maxWidth: PILLS_MAX_WIDTH
      }}
      error={error != undefined}
    >
      <DemographicPillsHeader
        demographicId={demographicId}
        label={label}
        t={t}
        isMultiSelect={isMultiSelect}
      />
      <RadioGroup
        name={`demographic-${demographicId}`}
        aria-labelledby={`demographic-${demographicId}-select-label`}
        data-component={`demographic-${demographicId}-radio-group`}
        orientation={'horizontal'}
        disableIcon
        overlay
        sx={{ ...CHIP_WRAP_PROPS, mt: 2 }}
      >
        {chips}
      </RadioGroup>

      <FormHelperText
        sx={{ alignSelf: 'center' }}
        aria-live={'polite'}
        data-component={`demographic-${demographicId}-error-message`}
      >
        {error}
      </FormHelperText>
    </FormControl>
  );
};
