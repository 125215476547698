import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Option,
  Select,
  Typography
} from '@mui/joy';
import { useCallback } from 'react';

const SELECT_MAX_WIDTH = 320;

export const DemographicSelectBox = ({
  demographicId,
  label,
  error,
  demographicKey,
  options,
  selected,
  disabled,
  isMultiSelect,
  setDemographicSelection
}) => {

  const { t } = useTranslation('demographics_page');

  const onChange = useCallback(
    (_, value) => {
      setDemographicSelection(demographicKey, value);
    },
    [demographicKey, setDemographicSelection]
  );

  return (
    <FormControl
      sx={{
        width: '100%',
        maxWidth: SELECT_MAX_WIDTH
      }}
      error={error != undefined}
      data-cy={`demographic-${demographicKey}`}
    >
      <FormLabel
        mb={1}
        htmlFor={`demographic-${demographicId}`}
        id={`demographic-${demographicId}-select-label`}
        data-cy={'demographic-label'}
        sx={{
          display: 'block',
          textAlign: 'left',
          fontSize: 24
        }}
      >
        {label}
        {isMultiSelect && (
          <Typography
            component={'span'}
            fontSize={'xs'}
          >
            {t('You can select more than one option.')}
          </Typography>
        )}
      </FormLabel>

      <Select
        name={demographicKey}
        required
        data-id={demographicId}
        data-cy={'demographic-selector'}
        data-key={demographicKey}
        value={selected}
        disabled={disabled}
        onChange={onChange}
        {...(isMultiSelect ? { multiple: true } : {})}
        slotProps={{
          button: {
            id: `demographic-${demographicId}`,
            'aria-labelledby': `demographic-${demographicId}-select-label`,
          }
        }}
      >
        {options.map(({ value, label }) => (
          <Option
            data-cy={`${demographicKey}-option`}
            sx={{ maxWidth: SELECT_MAX_WIDTH }}
            key={value}
            value={value}
            label={label}
            data-value={value}
          >
            {label}
          </Option>
        ))}
      </Select>
      <FormHelperText aria-live={'polite'} data-component={`demographic-${demographicId}-error-message`}>
        {error}
      </FormHelperText>
    </FormControl>
  );
};
