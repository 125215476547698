import { Button, Skeleton, Stack, Typography } from '@mui/joy';
import { PageHeaderLoadingSkeleton } from 'elements/PageHeaderLoadingSkeleton';

export const DemographicsLoadingSkeleton = () => {
  return (
    <>
      <PageHeaderLoadingSkeleton />
      <section data-component={'demographics-loading'} style={{ paddingTop: 40, maxWidth: '540px', display: 'flex', justifySelf: 'flex-start', alignSelf: 'center' }}>
        <Stack
          alignItems={'center'}
          spacing={{ xs: 5, md: 8 }}
        >
          <article>
            <Typography level={'h1'} sx={{ textAlign: 'center', mb: 3 }}>
              <Skeleton>
                Tell us about yourself
              </Skeleton>
            </Typography>

            <Typography level={'body-md'} sx={{ textAlign: 'center' }}>
              <Skeleton>
                We group this information with similar participants to analyse results.
                It can\'t be used to identify you or the answers you provide
                learn more about how your demographic data is used.
              </Skeleton>
            </Typography>
          </article>

          <Skeleton variant={'rectangular'} height={36} sx={{ maxWidth: '320px' }} />
          <Skeleton variant={'rectangular'} height={36} sx={{ maxWidth: '320px' }} />
          <Skeleton variant={'rectangular'} height={36} sx={{ maxWidth: '320px' }} />

          <Button
            className={'large-button'}
            size={'lg'}
          >
            Next
            <Skeleton />
          </Button>
        </Stack>
      </section>
    </>
  );
};
