import { useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useParams } from 'react-router-dom';
import { getAPI } from 'lib/APIHelpers';
import { DemographicsSelectionPage } from './DemographicsSelectionPage';

export const DependentDemographicPage = (props) => {
  const {
    demographics: propsDemographics,
    selected_demographics = {},
    setDemographicSelection,
    toggleDemographicSelection,
    errors,
    setErrors
  } = props;

  const { feedbackID } = useParams();

  const { isPending, data, isFetching } = useQuery({
    queryKey: ['getDependentDemographics', feedbackID],
    queryFn: () => getAPI(`api/v2/feedback/${feedbackID}/dependent_demographics`),
    throwOnError: true
  });

  const {
    parent_category_id,
    parent_category_name,
    dependent_category_id,
    dependent_category_name,
    parent_option_to_dependent_options
  } = data?.data || {};

  const selectedParentOption = useMemo(
    () => selected_demographics[parent_category_name]?.length > 0 ? selected_demographics[parent_category_name][0] : undefined,
    [selected_demographics, parent_category_name]
  );

  useEffect(
    () => {
      if (!dependent_category_name && !selectedParentOption) return;
      setDemographicSelection(dependent_category_name, undefined);
    },
    [selectedParentOption, dependent_category_name, setDemographicSelection]
  );

  // Pulls out the dependent demographics from the selected demographics and puts them first with the right dependent options
  const demographics = useMemo(
    () => {
      if (!parent_category_id && !dependent_category_id) return propsDemographics;
      const parent = propsDemographics.find(({ id }) => parseInt(id) == parent_category_id);
      const dependent = propsDemographics.find(({ id }) => parseInt(id) == dependent_category_id);
      dependent.disabled = !selectedParentOption;
      dependent.options = parent_option_to_dependent_options[selectedParentOption] || [];
      const other_demographics = propsDemographics.filter(({ id }) => parseInt(id) != parent_category_id && parseInt(id) != dependent_category_id);
      return [parent, dependent, ...other_demographics];
    },
    [
      propsDemographics,
      selectedParentOption,
      dependent_category_id,
      parent_category_id,
      parent_option_to_dependent_options
    ]
  );

  if (isPending || isFetching) return <></>;

  return (
    <DemographicsSelectionPage
      selected_demographics={selected_demographics}
      demographics={demographics}
      errors={errors}
      setDemographicSelection={setDemographicSelection}
      toggleDemographicSelection={toggleDemographicSelection}
      setErrors={setErrors}
    />
  );
};
