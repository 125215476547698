import { useParams } from 'react-router-dom';

import { DemographicsSelectionPage } from './DemographicsSelectionPage';
import { DependentDemographicPage } from './DependentDemographicPage';
import { DemographicsLoadingSkeleton } from './DemographicsLoadingSkeleton';
import { useDemographicApi } from './useDemographicApi';

export const Demographics = () => {
  const { feedbackID } = useParams();

  const {
    loading,
    demographics,
    errors,
    has_dependent_demographic_category,
    selected_demographics,
    setDemographicSelection,
    toggleDemographicSelection,
    setErrors
  } = useDemographicApi({ feedbackID });

  if (loading) return <DemographicsLoadingSkeleton />;

  if (has_dependent_demographic_category) return (
    <DependentDemographicPage
      selected_demographics={selected_demographics}
      demographics={demographics}
      setDemographicSelection={setDemographicSelection}
      toggleDemographicSelection={toggleDemographicSelection}
      errors={errors}
      setErrors={setErrors}
    />
  );

  return (
    <DemographicsSelectionPage
      demographics={demographics}
      selected_demographics={selected_demographics}
      errors={errors}
      setDemographicSelection={setDemographicSelection}
      toggleDemographicSelection={toggleDemographicSelection}
      setErrors={setErrors}
    />
  );
};

export default Demographics;
